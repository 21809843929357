<template>
  <div>
    <el-dialog title="专属资料" :visible.sync="materialVisible" width="660px" :before-close="materialClose">
      <div class="material" v-if="docList.length > 0">
        <div class="material_item" v-for="(item, index) in docList" :key="index">
          <div class="img">
            <img v-if="item.filetype == 'excel'" class="imgItem" src="@/assets/img/study/dataXls.png" />
            <img v-else-if="item.filetype == 'word'" class="imgItem" src="@/assets/img/study/dataDoc.png" />
            <img v-else-if="item.filetype == 'pdf'" class="imgItem" src="@/assets/img/study/dataPdf.png" />
            <img v-else-if="item.filetype == 'ppt'" class="imgItem" src="@/assets/img/study/dataPpt.png" />
            <img v-else class="imgItem" src="@/assets/img/study/dataFile.png" />
          </div>
          <div class="title elp">{{ item.typeName }}</div>
          <div class="btn">免费试看</div>
        </div>
      </div>
      <el-empty class="material" description="暂无数据" v-else></el-empty>
    </el-dialog>

    <!-- 知识点 -->
    <el-dialog :visible.sync="kowledgePointsVisible" width="50%" :before-close="kowledgePointsClose" :show-close="false">
      <div class="kowledgePoints_wrap">
        <kowledgePoints ref="kowledgePoints" type="4" />
        <div class="determine_btn" @click="kowledgePointsClose">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Know } from "@/api/know";
import { DataClient } from "@/api/dataClient";
import { getInfo } from "@/api/cookies";
const dataClient = new DataClient();
const know = new Know();
import Bus from "@/api/bus";
import Vue from "vue";
import kowledgePoints from "@/components/kowledgePoints.vue";
export default {
  components: {
    kowledgePoints,
  },
  props: ["materialVisible", "classesId", "direction"],
  data() {
    return {
      docPackage: [], // 资料包
      docList: [], // 资料
      userInfo: null,
      IsPurchase: null, // 是否购买
      radio: 1, // 1 资料包  2 资料
      packageList: [], // 资料包下的资料列表
      id: null, // 套餐ID

      kowledgePointsVisible: false,
    };
  },
  // created() {
  //   this.getClassesMaterials();
  // },
  methods: {
    init(id, IsPurchase) {
      this.IsPurchase = IsPurchase;
      this.id = id;
      this.getClassesMaterials(id)
      this.userInfo = getInfo();
    },
    /* 资料、资料包radio */
    radioChange() { },
    /* 专属资料 */
    getClassesMaterials(id) {
      if (this.direction) {
        const that = this;
        const data = {
          classesId: id,
          coursePackageId: this.direction,
        };
        know.getCoursePackageMaterialsList(data).then((res) => {
          for (const item of res.data.docList) {
            item.filetype = Vue.prototype.getFileType(item.url);
            Vue.prototype.getFilesize(item.url, function (size) {
              const sizeVal = (size / 1028).toFixed(0) + "k";
              const data = (size / 1028 / 1024).toFixed(0);
              that.$set(item, "size", data > 0 ? data + "M" : sizeVal);
              /* item.size = data > 0 ? data + "M" : sizeVal; */
              that.$forceUpdate();
            });
          }
          this.docList = res.data.docList;
          this.docPackage = res.data.docPackage;
        });
      } else {
        const that = this;
        know.getClassesMaterialsList(id).then((res) => {
          for (const item of res.data.docList) {
            item.filetype = Vue.prototype.getFileType(item.url);
            Vue.prototype.getFilesize(item.url, function (size) {
              const sizeVal = (size / 1028).toFixed(0) + "k";
              const data = (size / 1028 / 1024).toFixed(0);
              that.$set(item, "size", data > 0 ? data + "M" : sizeVal);
              /* item.size = data > 0 ? data + "M" : sizeVal; */
              that.$forceUpdate();
            });
          }
          this.docList = res.data.docList;
          this.docPackage = res.data.docPackage;
        });
      }
    },

    /* 资料详情 */
    getPackageDetails(i) {
      const that = this;
      if (this.userInfo) {
        dataClient
          .getPackageDetails(10000, 1, i.typeId, this.userInfo.id)
          .then((res) => {
            for (const item of res.rows) {
              item.filetype = Vue.prototype.getFileType(item.names);
              Vue.prototype.getFilesize(item.names, function (size) {
                const sizeVal = (size / 1028).toFixed(0) + "k";
                const data = (size / 1028 / 1024).toFixed(0);
                /* item.size = data > 0 ? data + "M" : sizeVal; */
                that.$set(item, "size", data > 0 ? data + "M" : sizeVal);
                /*  that.$forceUpdate(); */
              });
            }
            this.packageList = res.rows;
          });
      } else {
        Vue.prototype.goLoginView(false);
      }
    },
    /* 下载资料 */
    dowInfo(item) {
      const userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      if (this.IsPurchase && !item.type) {
        if (item.names) {
          const data = {
            zlId: item.mid,
            xmId: item.kind,
            dlId: item.pid,
            linkLocation: item.names,
            name: item.title,
            type: 1,
          };
          dataClient.downloadRecord(data);
          Vue.prototype.DownloadDoc(item.names, item.title);
        } else {
          this.$notify({
            title: "错误",
            message: "暂无资源",
            type: "error",
            duration: 2000,
          });
        }
      } else if (this.IsPurchase && item.type == 4) {
        if (item.url) {
          const data = {
            zlId: item.id,
            xmId: item.kind,
            dlId: item.pId,
            linkLocation: item.url,
            name: item.typeName,
            type: 1,
          };
          dataClient.downloadRecord(data);
          Vue.prototype.DownloadDoc(item.url, item.typeName);
        } else {
          this.$notify({
            title: "错误",
            message: "暂无资源",
            type: "error",
            duration: 2000,
          });
        }
      } else {
        this.$notify({
          title: "错误",
          message: "未购买，无法查看, 请先去购买",
          type: "error",
          duration: 2000,
        });
      }
    },
    // 点击跳转到详情页
    checkClick(item) {
      const userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      if (this.IsPurchase) {
        this.$router.push(
          `/data/dataPackageDetail?packageId=${item.typeId}&classesId=${this.classesId}`
        );
      } else {
        this.$notify({
          title: "错误",
          message: "未购买，无法下载, 请先去购买",
          type: "error",
          duration: 2000,
        });
      }
    },
    /* 免费试看 */
    examination(item) {
      if (this.userInfo) {
        this.$router.push({
          path: "/VIP/sourcePage",
          query: {
            IsPurchase: this.IsPurchase,
            seeNum: item.seeNum,
            names: item.names ? item.names : item.url,
            id: this.id,
            type: 1,
          },
        });
      } else {
        Vue.prototype.goLoginView(false);
      }
    },
    /* 关闭回调 */
    materialClose() {
      this.docList = [];
      this.docPackage = [];
      this.$emit("materialClose");
    },

    // 知识点
    openKowledge(id) {
      console.log(id, "---------id");
      this.kowledgePointsVisible = true;
      this.$nextTick(() => {
        this.$refs.kowledgePoints.getData(id);
      });
    },
    kowledgePointsClose() {
      this.kowledgePointsVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  background: linear-gradient(180deg,
      rgba(205, 226, 251, 1) 0%,
      rgba(255, 255, 255, 1) 100%);
  text-align: center;
  font-size: 22px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
}

.material {
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;

  .material_item {
    width: 624px;
    height: 60px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;

    .img {
      width: 24px;
      height: 26px;
      margin-right: 12px;

      .imgItem {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      width: 300px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      text-align: left;
    }

    .btn {
      width: 88px;
      height: 30px;
      border-radius: 5px;
      opacity: 1;
      border: 1px solid #00a2eb;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #00a2eb;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 141px;
    }
  }
}

.kowledge_btn {
  width: 88px;
  height: 30px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 14px;
  color: #666666;
  line-height: 30px;
  text-align: center;
  margin-top: 35px;
  margin-right: 20px;
  flex-shrink: 0;
  cursor: pointer;
}

.noMsg {
  text-align: center;
  line-height: 400px;
}

.collapse-item {
  height: 500px;
}

.kowledgePoints_wrap {
  padding-bottom: 20px;

  .determine_btn {
    width: 40%;
    height: 40px;
    margin: 0 auto;
    margin-top: 20px;
    background-color: #ff5e51;
    color: #ffffff;
    font-weight: 500;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
}

.materialWrap ::v-deep .el-collapse-item__header {
  height: 140px;
  border: none;
  line-height: 16px;
}

.materialWrap ::v-deep .collapse-item {
  height: auto;
}
</style>
