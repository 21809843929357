<template>
  <div>
    <el-dialog title="专属教服" :visible.sync="educateVisible" width="660px" :before-close="educateClose">
      <div class="educateWrap">
        <div class="before_test">
          <div class="text_title">
            <el-image class="img" :src="require('@/assets/img/partner/3 (1).png')"></el-image>
            <div class="title">考前答疑</div>
          </div>
          <div class="before">
            <div class="img">
              <el-image :src="require('../../../assets/img/partner/arrive.png')"></el-image>
            </div>
            <div class="before_right">
              <div class="before_title">
                考前答疑考前答疑考前答疑考前答疑考前答疑考前答疑考前答疑答疑考
              </div>
              <div class="time">
                <el-image class="img" :src="require('@/assets/img/partner/3 (3).png')"></el-image>
                直播时间：2023.06.30 15:30
              </div>
            </div>
          </div>
          <div class="test"></div>
        </div>
        <div class="serve">
          <div class="title">
            <el-image class="title_img" :src="require('@/assets/img/partner/3 (6).png')"></el-image>
            <span class="title_tip">专属服务</span>
          </div>
          <div class="main">
            <div v-for="item in jfType" v-show="jfType.length > 0" :key="item" class="mainItam">
              <img v-if="item == '考试通知'" class="mainItamIcon" src="@/assets/img/partner/1.png" />
              <img v-if="item == '督学跟踪'" class="mainItamIcon" src="@/assets/img/partner/2.png" />
              <img v-if="item == '定期班会'" class="mainItamIcon" src="@/assets/img/partner/3 (2).png" />
              <img v-if="item == '在线答疑'" class="mainItamIcon" src="@/assets/img/partner/3 (5).png" />
              <span class="item_title">{{ item }}</span>
            </div>
          </div>
        </div>
        <div class="details">
          <div class="details_title">
            <el-image class="title_img" :src="require('@/assets/img/partner/3 (4).png')"></el-image>
            <span class="title_tip">教服详情</span>
          </div>
          <div class="jiaofu" v-html="ImgSizeLimit(jfServer)" />
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="educateClose" size="mini">关闭</el-button> -->
        <!-- <el-button type="primary" @click="dialogVisible = false" size="mini">确 定</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["educateVisible"],
  data() {
    return {
      classMeetingList: [
        {
          index: 1,
          value: "2021年元宇宙概念班会-第二期",
          time: "2021-12-10 12:30",
          people: 3000,
        },
        {
          index: 2,
          value: "2021年元宇宙概念班会-第二期",
          time: "2021-12-10 12:30",
          people: 3000,
        },
        {
          index: 3,
          value: "2021年元宇宙概念班会",
          time: "2021-12-10 12:30",
          people: 3000,
        },
        {
          index: 4,
          value: "2021年元宇宙概念班会",
          time: "2021-12-10 12:30",
          people: 3000,
        },
      ],
      jfServer: null,
      jfType: [],
    };
  },
  methods: {
    init(jfServer, detalilsList) {
      this.jfServer = jfServer;
      this.jfType = detalilsList.jfType.split(",");
    },
    /* 关闭回调 */
    educateClose() {
      this.$emit("educateClose");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  background: linear-gradient(180deg,
      rgba(205, 226, 251, 1) 0%,
      rgba(255, 255, 255, 1) 100%);
  text-align: center;
  font-size: 22px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
}

.educateWrap {
  padding-top: 20px;
  width: 100%;
  height: 500px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .before_test {
    width: 620px;
    // height: 130px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    padding: 20px;
    margin-bottom: 20px;

    .text_title {
      display: flex;
      align-items: center;

      .img {
        width: 15px;
        height: 15px;
      }

      .title {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-left: 8px;
      }
    }

    .before {
      display: flex;
      align-items: flex-start;
      margin-top: 14px;

      .img {
        width: 126px;
        height: 70px;
        border-radius: 6px 6px 6px 6px;

        .el-image {
          width: 126px;
          height: 70px;
          border-radius: 6px 6px 6px 6px;
        }
      }

      .before_right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 20px;

        .before_title {
          margin-top: 4px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }

        .time {
          .img {
            margin-right: 3px;
            width: 14px;
            height: 14px;
          }

          margin-top: 14px;
          width: 228px;
          height: 28px;
          background: rgba(0, 162, 235, 0.1);
          border-radius: 4px 4px 4px 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular,
          Microsoft YaHei;
          font-weight: 400;
          color: #00a2eb;
        }
      }
    }
  }

  .serve {
    width: 620px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      align-items: center;

      .title_img {
        width: 15px;
        height: 16px;
        margin-right: 8px;
      }

      .title_tip {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
    }

    .main {
      margin-top: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .mainItam {
        width: 140px;
        height: 57px;
        background: #f9fafc;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .mainItamIcon {
          width: 17px;
          height: 17px;
          margin-right: 8px;
        }

        .item_title {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }

  .details {
    width: 620px;
    height: 130px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .details_title {
      .title_img {
        width: 15px;
        height: 16px;
        margin-right: 8px;
      }

      .title_tip {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
    }

    .jiaofu {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-top: 14px;
    }
  }
}
</style>
