<template>
  <div>
    <el-dialog title="专属教辅" :visible.sync="bookVisible" width="660px" :before-close="bookClose">
      <div class="textBookWrap">
        <div v-for="(item, index) in textBookList" :key="index" class="textBook">
          <div class="textBookItem">
            <div class="imageWrap">
              <el-image :src="item.faceImg" class="imageItem" />
              <!-- <div class="tabs">可试看</div> -->
            </div>
            <div>
              <div class="titleItem twoEllipsis">{{ item.textbookName }}</div>
              <div class="sasuke">
                简介：
                <div class="content" v-html="ImgSizeLimit(item.textBookNote)" />
              </div>
            </div>
            <div class="btn">
              <el-button v-if="!IsPurchase" class="detail_btn" size="mini" @click="seeTextbook(item)">免费试看</el-button>
              <el-button v-else size="mini" type="danger" @click="seeTextbook(item)">阅读教材</el-button>
              <el-button size="mini" type="" class="view_btn" @click="goTextbookDetails(item)">教材详情</el-button>

            </div>
          </div>
          <!-- <el-divider /> -->
        </div>
        <div v-if="textBookList.length == 0" class="nothing">
          <div class="nothing-text">暂无内容哦~</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getClassTeaching, getClassCourseTeaching } from "@/api/home.js";
import { getInfo } from "@/api/cookies";
import Bus from "@/api/bus";
import Vue from "vue";
export default {
  components: {},
  props: ["bookVisible", "IsPurchase", "direction"],
  data() {
    return {
      textBookList: [],
      classesId: null,
      title: null, // 套餐名称

      userInfo: getInfo(),
    };
  },
  created() { },
  methods: {
    init(id, IsPurchase, title) {
      this.classesId = id;
      this.title = title;
      this.getClassTeaching(id);
    },
    /* 教材列表 */
    getClassTeaching(id) {
      if (this.direction) {
        const data = {
          id,
          coursePackageId: this.direction,
        };
        getClassCourseTeaching(data).then((res) => {
          this.textBookList = res.msg;
        });
      } else {
        getClassTeaching(id).then((res) => {
          this.textBookList = res.msg;
        });
      }
    },
    /* 点击教材详情 */
    goTextbookDetails(item) {
      this.$router.push({
        path: "/typeclassxq/textbookDetails",
        query: {
          id: item.id,
          title: this.title,
          classesId: this.classesId,
          IsPurchase: this.IsPurchase,
        },
      });
    },
    /* 点击阅读/试看教材 */
    seeTextbook(item) {
      const userInfo = getInfo();
      if (userInfo) {
        this.$router.push({
          path: "/VIP/textbookPage",
          query: {
            IsPurchase: this.IsPurchase,
            id: item.id,
            classesId: this.classesId,
            title: this.title,
          },
        });
      } else {
        Vue.prototype.goLoginView(false);
      }
    },
    /* 关闭回调 */
    bookClose() {
      this.$emit("bookClose");
    },
  },
};
</script>

<style lang="less" scoped>
.textBook {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textBook /deep/ .el-divider {
  margin: 0;
  width: 596px;
  background-color: #eeeeee;
}

.textBookWrap {
  // background-color: #fff;
  height: 500px;
  overflow: auto;
}

.textBookItem {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  position: relative;
  width: 624px;
  height: 108px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  padding: 20px;
}

.imageWrap {
  width: 74px;
  height: 100px;
  position: relative;
  margin-right: 14px;
  top: -30px;
}

.imageItem {
  width: 74px;
  height: 100px;
  border-radius: 8px 8px 8px 8px;
}

.tabs {
  width: 52px;
  height: 20px;
  background-color: rgba(255, 94, 81, 1);
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 20px;
  border-radius: 8px 0px 8px 0px;
}

.titleItem {
  width: 360px;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 26px;
}

.author {
  margin-top: 16px;
  color: rgba(102, 102, 102, 1);
}

.sasuke {
  display: flex;

  height: 37px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #818395;
  line-height: 20px;

  .tag {
    width: 40px;
  }

  .content {
    width: 360px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.btn {
  display: flex;
  align-items: center;
  flex-direction: column;

  // margin-left: 20px;
  .detail_btn {
    width: 88px;
    height: 30px;
    opacity: 1;
    border: 1px solid #00a2eb;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #00A2EB;
  }

  .view_btn {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #777A82;
    margin-left: 0;
    border: none;
    margin-top: 5px;
  }

  // margin-left: 10px;
}

::v-deep .el-dialog {
  background: linear-gradient(180deg,
      rgba(204, 225, 250, 1) 0%,
      rgba(255, 255, 255, 1) 100%);

  opacity: 1;
  border-radius: 11px 11px 11px 11px;
  width: 664px;
}

::v-deep .el-dialog__header {
  text-align: center;

  font-size: 22px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

::v-deep .el-dialog__body {
  padding: 0 0 !important;
}
</style>
